import React from 'react';
import PropTypes from 'prop-types';

const TileContainer = (props) => {
    const {
        children,
        columns,
        homepageLayout,
    } = props;

    return (
        <div className={`tile-container${homepageLayout ? ' homepage-layout' : ''} ${columns ? columns : 'three_columns'}`}>
            {children}
        </div>
    );
};

TileContainer.propTypes = {
    children: PropTypes.node.isRequired,
    columns: PropTypes.string,
    homepageLayout: PropTypes.bool,
};

export default TileContainer;

