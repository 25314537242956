import React, { useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import NewsTile from '~2-components/NewsTile/NewsTile';
import NewsTileKontent from '~3-data-components/NewsTileKontent';
import TileContainer from '~2-components/TileContainer/TileContainer';
import { dummyNewsListingData } from '~data/dummyNewsListingData';

const NewsTileEg = `import NewsTile from '~2-components/NewsTile/NewsTile';

<NewsTile
    url = '' //Required
    cmsImage = React.node //Required if coming from CMS. Usually a Gatsby Image.
    imgUrl = '' //Required if NOT coming from CMS
    imgAlt = '' //Required if imgUrl is set.
    date = '' //Required
    title = '' //Required
/>
`;

const SgNewsTile = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const tileData = useStaticQuery(graphql`
        query SgNewsTile {
            allKontentItemNewsArticle {
                nodes {
                    system {
                        id
                    }
                }
            }
        }
    `);

    const allCMSTiles = tileData.allKontentItemNewsArticle.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>News Tile</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={NewsTileEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container eg-tiles three-columns">
                    {dummyNewsListingData.map(item => {
                        return (
                            <NewsTile
                                key={item.url}
                                url={item.url}
                                imgUrl={item.imgUrl}
                                imgAlt={item.imgAlt}
                                date={item.date}
                                title={item.title}
                            />
                        );
                    })}
                </div>
            </section>

            <section className="sg-content">
                <div className="container">
                    <h3>All live CMS News Tiles</h3>
                </div>
            </section>

            <section className="project-example">
                <div className="container">
                    <TileContainer>
                        {allCMSTiles.map(tile => {
                            return (
                                <NewsTileKontent key={tile.system.id} system={tile.system} />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgNewsTile;
