export const dummyNewsListingData = [
    {
        url: '/hello-world',
        imgUrl: 'https://source.unsplash.com/featured/?people',
        imgAlt: 'News tile image',
        date: '2 Nov 2019',
        title: 'Age is no barrier to compensation'
    },
    {
        url: '/hello-world-1',
        imgUrl: 'https://source.unsplash.com/featured/?healthcare',
        imgAlt: 'News tile image',
        date: '2 Nov 2019',
        title: 'Healthcare workers encouraged to report needle stick injuries'
    },
    {

        url: '/hello-world-2',
        imgUrl: 'https://source.unsplash.com/featured/?worker',
        imgAlt: 'News tile image',
        date: '2 Nov 2019',
        title: 'Can employees take or accrue leave while also receiving workers’ compensation?'
    }
];
