const formatURL = url => {
    let formattedUrl;
    if (!url.startsWith("/")) {
        formattedUrl = `/${url}`;
    } else {
        formattedUrl = url;
    }

    return formattedUrl;
};

export default formatURL;
